import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-scroll';
import MailingListModal from './mailing-list-modal';

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(state => ({
      modalVisible: !state.modalVisible,
    }));
  }

  render() {
    const { modalVisible } = this.state;

    return (
      <div className='bg-blue home-hero'>
        <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
          <div className='lg:w-2/5'>
            <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
              We want to change the future of healthcare
            </h1>

            <h4 className='font-soft font-semibold text-blue-900 text-xl leading-snug mb-4'>
              The NL Genome Project is a local genetic research study.
            </h4>

            <p className='font-sans font-normal text-white mb-4'>
              By studying our province’s one-of-a-kind DNA and health information, we hope to discover better, safer medicines and improve how we treat and prevent diseases.
            </p>
          </div>
        </div>

        <MailingListModal
          modalVisible={modalVisible}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

export default Hero;
